<template>
  <h3>Test</h3>
</template>

<script>
export default {
  name: 'Testing'
}
</script>

<style lang="scss">

</style>
